
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1b9047d53d9f9d22': `2) Drücken Sie auf Teilen in der Navigationsleiste`,
's37a9e8aec5713460': `Mehr`,
's5c6aad7a7e4a1437': `3) Drücken Sie Hinzufügen zum Startbildschirm`,
's6196153c4b0c1ea0': `Installieren`,
'sa5ef80b4bb9b39f8': `Weniger`,
'sc79fd7641eb9a975': `Anleitung ausblenden`,
'scdaf4bbff76674c8': `Hinzufügen zum Startbildschirm`,
'sdfe23506a3b0cdc8': `1) In Safari-Browser öffnen`,
'sa30904a5d8dccb8c': `This site has app functionality. Install it on your device for extensive experience.`,
's05605d6fa3784bd7': `This site has app functionality. Add it to your Home Screen for extensive experience.`,
    };
  