
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1b9047d53d9f9d22': `2) Gezinme çubuğunda Paylaş'a basın`,
's37a9e8aec5713460': `Daha Fazla`,
's5c6aad7a7e4a1437': `3) Ana Ekrana Ekle'ye basın`,
's6196153c4b0c1ea0': `Yükle`,
'sa5ef80b4bb9b39f8': `Küçült`,
'sc79fd7641eb9a975': `Talimatları gizle`,
'scdaf4bbff76674c8': `Ana Ekrana Ekle`,
'sdfe23506a3b0cdc8': `1) Safari tarayıcısında açın`,
'sa30904a5d8dccb8c': `This site has app functionality. Install it on your device for extensive experience.`,
's05605d6fa3784bd7': `This site has app functionality. Add it to your Home Screen for extensive experience.`,
    };
  